import React, { Suspense, lazy, useEffect, useState } from "react";
import { Router, Routes, Route, useParams } from "react-router-dom";
import API from "./helpers/API";
import { useAuth } from "./Context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Loader from "./ui/Loader/Loader";
import $ from "jquery";

import "./App.css";

import { AdminRoute } from "./routes/AdminRoute";
import { ApplicantRoute } from "./routes/ApplicantRoute";
// import OAuthSuccess from "./components/OAuthSuccess/OAuthSuccess";

// const EmailVerification = lazy(
//   () => import("./features/Account/EmailConfirmation/EmailVerification")
// );
// const Home = lazy(() => import("./features/Home/Home"));
const Home = lazy(() => import("./Pages/Home"));
const JobSearchInterface = lazy(() => import('./Pages/JobSearchInterface'));
const Login = lazy(() => import('./Pages/Login/Login'));
const Register = lazy(() => import('./Pages/Register/Register'));
const AdminDashboardHome = lazy(() => import('./Pages/Admin/Home'));
const ManageTestJobs = lazy(() => import('./Pages/Admin/Jobs/ManageTestJobs'));
const AddTestJob = lazy(() => import('./Pages/Admin/Jobs/AddTestJob'));
const ManageAdminJobTestQualifications = lazy(() => import('./Pages/Admin/Qualifications/ManageAdminJobTestQualifications'));
const AddTestQualification = lazy(() => import('./Pages/Admin/Qualifications/AddTestQualification'));
const ManageAdminTestWorkExperiences = lazy(() => import('./Pages/Admin/WorkExperiences/ManageAdminTestWorkExperiences'));
const AddTestWorkExperience = lazy(() => import('./Pages/Admin/WorkExperiences/AddTestWorkExperience'));
const ManageAdminTestWorkModes = lazy(() => import('./Pages/Admin/WorkModes/ManageAdminTestWorkModes'));
const AddTestWorkMode = lazy(() => import('./Pages/Admin/WorkModes/AddTestWorkMode'));
const AddTestCountry = lazy(() => import('./Pages/Admin/Countries/AddTestCountry'));
const ManageAdminJobTestCountries = lazy(() => import('./Pages/Admin/Countries/ManageAdminTestCountries'));
const AddTestProvince = lazy(() => import('./Pages/Admin/Provinces/AddTestProvince'));
const ManageAdminTestProvinces = lazy(() => import('./Pages/Admin/Provinces/ManageAdminTestProvinces'));
const AddTestSector = lazy(() => import('./Pages/Admin/Sectors/AddTestSector'));
const ManageAdminTestSectors = lazy(() => import('./Pages/Admin/Sectors/ManageAdminTestSectors'));
const AddTestSalary = lazy(() => import('./Pages/Admin/Salaries/AddTestSalary'));
const ManageAdminTestSalaries = lazy(() => import('./Pages/Admin/Salaries/ManageAdminTestSalaries'));
const AllContactUsMessages = lazy(() => import('./Pages/Admin/ContactMessages/AllContactUsMessages'));
const TestViewContactMessage = lazy(() => import('./Pages/Admin/ContactMessages/TestViewContactMessage'));
const TestAdminSubscribers = lazy(() => import('./Pages/Admin/Subscribers/TestAdminSubscribers'));
const ManageAdminTestWorkAuthorizations = lazy(() => import('./Pages/Admin/WorkAuthorizations/ManageAdminTestWorkAuthorizations'));
const AddTestWorkAuthorization = lazy(() => import('./Pages/Admin/WorkAuthorizations/AddTestWorkAuthorization'));
const AdminTestChangePassword = lazy(() => import('./Pages/Admin/Account/AdminTestChangePassword'));

const AboutUs = lazy(() => import('./Pages/About/AboutUs'));
const WhoWeAre = lazy(() => import('./Pages/About/WhoWeAre'));
const OurLocations = lazy(() => import('./Pages/About/OurLocations'));
const ContactUs = lazy(() => import('./Pages/Contact/TestContactUs'));
const ThankYou = lazy(() => import('./Pages/Contact/TestThankYou'));
const ITRecruitment = lazy(() => import('./Pages/Business/ITRecruitment/ITRecruitment'));
const GovtMinistryRecruitment = lazy(() => import('./Pages/Business/GovtMinistry/GovtMinistryRecruitment'));
const HealthCareMedicalRecruitment = lazy(() => import('./Pages/Business/HealthcareMedicalRecruitment/HealthcareMedicalRecruitment'));
const ManufacturingRecruitment = lazy(() => import('./Pages/Business/ManufacturingRecruitment/ManufacturingRecruitment'));
const AdminOfficeSupportRecruitment = lazy(() => import('./Pages/Business/AdminOfficeSupportRecruitment/AdminOfficeSupportRecruitment'));
const TransportLogisticsRecruitment = lazy(() => import('./Pages/Business/TransportLogisticsRecruitment/TransportLogisticsRecruitment'));
const ConstructionInfrastructureRecruitment = lazy(() => import('./Pages/Business/ConstructionInfrastructureRecruitment/ConstructionInfrastructureRecruitment'));
const EducationRecruitment = lazy(() => import('./Pages/Education/EducationRecruitment'));
const PermanentRecruitment = lazy(() => import('./Pages/Employers/PermanentRecruitment/PermanentRecruitment'));
const TailoredSolutionsForEmployers = lazy(() => import('./Pages/Employers/TailoredSolutionsForEmployers/TailoredSolutionsForEmployers'));
const FluidWorkforce = lazy(() => import('./Pages/Employers/FluidWorkforce/FluidWorkforce'));
const ThinkBeyondPrivacyPolicy = lazy(() => import('./Pages/ThinkBeyondPrivacyPolicy/ThinkBeyondPrivacyPolicy'));

const EmailVerification = lazy(() => import('./Pages/EmailConfirmation/PageEmailVerification'));
const ForgotPassword = lazy(() => import('./Pages/PasswordActions/ForgotPassword'));
const PasswordResetLink = lazy(() => import('./Pages/PasswordActions/PasswordResetLink'));
const PageChangePassword = lazy(() => import('./Pages/PasswordActions/ChangePassword'));

// *************************** Pages Applicant Dashboard Start *********************************************
const TestApplicantDashboard = lazy(() => import('./Pages/Applicant/ApplicantHome'));
const PageProfile = lazy(() => import('./Pages/Applicant/Profile/Profile'));
const ApplicantResumeUpload = lazy(() => import('./Pages/ResumesCV/ApplicantResumeUpload'));
// *************************** Pages Applicant Dashboard End ***********************************************


// const AboutUs = lazy(() => import("./features/About/AboutUs"));
const OurMission = lazy(() => import("./features/OurMission/OurMission"));
const OurVision = lazy(() => import("./features/OurVision/OurVision"));
const OurCoreServices = lazy(
  () => import("./features/TBSCoreServices/OurCoreServices")
);
const ITInfrastructure = lazy(
  () => import("./features/ITInfrastructure/ITInfrastructure")
);
const BehavioralHealth = lazy(
  () => import("./features/BehavioralHealth/BehavioralHealth")
);
const Education = lazy(() => import("./features/Education/Education"));
const HealthBillingCollection = lazy(
  () => import("./features/HealthBillingCollections/HealthBillingCollections")
);
const HealthcareMergersAndAcquisitions = lazy(
  () => import("./features/M&A/HealthcareM&A")
);
const RecruitingAndLeadership = lazy(
  () => import("./features/Recruiting&Leadership/Recruiting&Leadership")
);
const BehavioralHealthCredentialingAndContracting = lazy(
  () =>
    import(
      "./features/BehavioralHealthCredentialing&Contracting/BehavioralHealthCredentialing&Contracting"
    )
);
const WebDevelopmentServices = lazy(
  () => import("./features/WebDevelopmentServices/WebDevelopmentServices")
);
const DataAnalytics = lazy(
  () => import("./features/DataAnalytics/DataAnalytics")
);
const HigherEducation = lazy(
  () => import("./features/HigherEducation/HigherEducation")
);
const EducationCounselling = lazy(
  () => import("./features/EducationCounselling/EducationCounselling")
);
const Infrastructure = lazy(
  () => import("./features/InfrastructureServices/Infrastructure")
);
const Employers = lazy(() => import("./features/Employers/Employers"));
const ApplyJobForm = lazy(() => import("./features/Account/ApplyJobForm"));
const ApplyNonITJobForm = lazy(
  () => import("./features/Account/ApplyNonITJobForm")
);
// const BrowseJobs = lazy(() => import("./features/Jobs/BrowseJobs"));
const NonITJobs = lazy(() => import("./features/Jobs/NonITJobs"));
const JobDetails = lazy(() => import("./features/Jobs/JobDetails"));
const NonITJobDetails = lazy(() => import("./features/Jobs/NonItJobDetails"));
const SearchJobFilter = lazy(() => import("./features/Jobs/SearchJobFilter"));
// const ContactUs = lazy(() => import("./features/Contact/ContactUs"));
// const ThankYou = lazy(() => import("./features/Contact/ThankYou"));
// const ResumeUpload = lazy(() => import("./features/ResumeUpload/ResumeUpload"));
// const Login = lazy(() => import("./features/Account/Login/Login"));
// const Register = lazy(() => import("./features/Account/Register/Register"));

//Privacy Policy
const PrivacyPlicy = lazy(() => import("./features/PrivacyPolicy/PrivacyPolicy"));

// const ForgotPassword = lazy(
//   () => import("./features/Account/ForgotPassword/ForgotPassword")
// );
// const ResetPassword = lazy(
//   () => import("./features/Account/ForgotPassword/ResetPassword")
// );

const RecruitmentProcess = lazy(
  () => import("./features/Home/Recruitment-Process-Outsourcing")
);

const SoftwareDevelopment = lazy(
  () => import("./features/Home/Software-Development")
);

const CandidateSourcing = lazy(
  () => import("./features/Home/Candidate-Sourcing")
);

// const AdminDashboardHome = lazy(() => import("./admin/Home"));
const AddCountry = lazy(() => import("./admin/Countries/AddCountry"));
const ManageCountries = lazy(() => import("./admin/Countries/ManageCountries"));
const AddSalary = lazy(() => import("./admin/Salaries/AddSalary"));
const ManageSalaries = lazy(() => import("./admin/Salaries/ManageSalaries"));
const AddProvince = lazy(() => import("./admin/Provinces/AddProvince"));
const ManageProvinces = lazy(() => import("./admin/Provinces/ManageProvinces"));
const AddSector = lazy(() => import("./admin//Sectors/AddSector"));
const ManageSectors = lazy(() => import("./admin/Sectors/ManageSectors"));

const ManageProducts = lazy(() => import("./admin/Products/ManageProducts"));
const ManageProductCategories = lazy(
  () => import("./admin/ProductCategory/ManageProductCategories")
);

const AddQualification = lazy(
  () => import("./admin/Qualifications/AddQualification")
);
const ManageQualifications = lazy(
  () => import("./admin/Qualifications/ManageQualifications")
);
const AddWorkExperience = lazy(
  () => import("./admin/WorkExperiences/AddWorkExperience")
);
const ManageWorkExperiences = lazy(
  () => import("./admin/WorkExperiences/ManageWorkExperiences")
);
const AddWorkMode = lazy(() => import("./admin/WorkModes/AddWorkMode"));
const ManageWorkModes = lazy(() => import("./admin/WorkModes/ManageWorkModes"));
const AddWorkAuthorization = lazy(
  () => import("./admin/WorkAuthorizations/AddWorkAuthorization")
);
const ManageWorkAuthorizations = lazy(
  () => import("./admin/WorkAuthorizations/ManageWorkAuthorizations")
);
const PostJob = lazy(() => import("./admin/Jobs/PostJob"));
const PostNonITJob = lazy(() => import("./admin/Jobs/PostNonITJob"));
const ManageJobs = lazy(() => import("./admin/Jobs/ManageJobs"));
const ManageNonITJobs = lazy(() => import("./admin/Jobs/ManageNonITJobs"));
const DeleteJob = lazy(() => import("./admin/Jobs/DeleteJob"));
const DeleteNonITJob = lazy(() => import("./admin/Jobs/DeleteNonITJob"));
const UpdateJob = lazy(() => import("./admin/Jobs/UpdateJob"));
const UpdateNonITJob = lazy(() => import("./admin/Jobs/UpdateNonITJob"));
const ViewJobDetails = lazy(() => import("./admin//Jobs/ViewJobDetails"));
const ViewNonITJobDetails = lazy(
  () => import("./admin/Jobs/ViewNonITJobDetails")
);

const ManageContactMessages = lazy(
  () => import("./admin/ContactMessages/ManageContactMessages")
);
const ViewContactMessage = lazy(
  () => import("./admin/ContactMessages/ViewContactMessage")
);

const DeleteContactMessage = lazy(
  () => import("./admin//ContactMessages/DeleteContactMessage")
);

const AdminChangePassword = lazy(
  () => import("./admin/ChangePassword/ChangePassword")
);

const Subscribers = lazy(() => import("./admin//Subscribers/Subscribers"));

// const ApplicantDashboardHome = lazy(() => import("./applicant/Home"));
// const ApplicantProfile = lazy(
//   () => import("./applicant/ApplicantProfiles/ApplicantProfile")
// );
const ApplicantAppliedJobs = lazy(
  () => import("./applicant/Jobs/ApplicantAppliedJobs")
);
const ApplicantChangePassword = lazy(
  () => import("./applicant/ChangePassword/ChangePassword")
);
const DeleteEducation = lazy(
  () => import("./applicant//Education/DeleteEducation")
);
const DeleteApplicantExperience = lazy(
  () => import("./applicant/Experience/DeleteApplicatExperience")
);
const DeleteSkill = lazy(() => import("./applicant/Skill/DeleteSkill"));

// Delete User Resume from Applicant Dashboard
const DeleteResume = lazy(() => import("./applicant/Resume/DeleteResume"));

// UnAuthorized Page
const UnAuthorized = lazy(() => import("./features/UnAuthorized/UnAuthorized"));

// Unsubscribe
const Unsubscribe = lazy(() => import("./features/Unsubscribe/Unsubscribe"));
const UsersSubscriptionFormDetails = lazy(
  () => import("./features/UsersSubscription/UsersSubscription")
);

const Shop = lazy(() => import("./features/Shop/Shop"));
const ProductDetail = lazy(() => import("./features/Shop/ProductDetail"));
const Cart = lazy(() => import("./features/Shop/Cart"));

// Customer Orders
const Orders = lazy(() => import("./applicant/CustomerOrders/Orders"));

const LinkedInCallback = lazy(() => import('./components/LinkedIn/LinkedInCallback'));
const AuthSuccess = lazy(() => import('./components/LinkedIn/AuthSuccess'));


const StyledContainer = styled(ToastContainer)`
        &&&.Toastify__toast-container {
        }
        .Toastify__toast {
        width: 550px !important;
        height: 200px;
        font-family: Trebuchet-ms;
        font-weight: bold;
        }
        .Toastify__toast-body {
        width: 550px !important;
        height: 200px
        font-family: Trebuchet-ms;
        }
        .Toastify__progress-bar {    
        }
  `;
function App() {
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState({});
  const params = useParams();
  const fetchRegUserById = async () => {
    try {
      const { data } = await API.get(`/users/fetchRegUserById/${params._id}`);
      setUser(data.user);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (params?._id) fetchRegUserById();
  }, [params?._id]);

  useEffect(() => {
    const loadScripts = async () => {
      const [jqueryResponse, mainResponse] = await Promise.all([
        fetch("./assets/js/jquery-3.3.1.min.js"),
        fetch("./assets/js/bootstrap.min.js"),
        fetch("./assets/js/popper.min.js"),
        fetch("./assets/js/main.js")
      ]);

      const [jqueryText, mainText] = await Promise.all([
        jqueryResponse.text(),
        mainResponse.text()
      ]);
      const jqueryScript = document.createElement("script");
      jqueryScript.text = jqueryText;
      document.body.appendChild(jqueryScript);
      const mainScript = document.createElement("script");
      mainScript.text = mainText;
      document.body.appendChild(mainScript);
    };
    loadScripts();
    return () => {
      // Cleanup code remains the same
    };
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/Search-Jobs" element={<JobSearchInterface />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          {/* <Route path="/Admin/Test-Admin-Dashboard" element={<TestDashboardHome />} /> */}
          {/* <Route path="/Admin/Test-Manage-Jobs" element={<ManageTestJobs />} /> */}
          <Route path="About-Us" element={<AboutUs />} />
          <Route path="Who-We-Are" element={<WhoWeAre />} />
          <Route path="Our-Locations" element={<OurLocations />} />
          <Route path="/Contact-Us" element={<ContactUs />} />
          <Route path="/Thank-You" element={<ThankYou />} />

          <Route path="/Information-Technology-Recruitment" element={<ITRecruitment />} />
          <Route path="/Government-Ministry-Recruitment" element={<GovtMinistryRecruitment />} />
          <Route path="/Healthcare-&-Medical-Recruitment" element={<HealthCareMedicalRecruitment />} />
          <Route path="/Manufacturing-Recruitment" element={<ManufacturingRecruitment />} />
          <Route path="/Admin-&-Office-Support-Recruitment" element={<AdminOfficeSupportRecruitment />} />
          <Route path="/Transport-&-Logistics-Recruitment" element={<TransportLogisticsRecruitment />} />
          <Route path="/Construction-&-Infrastructure-Recruitment" element={<ConstructionInfrastructureRecruitment />} />
          <Route path="/Education-Recruitment" element={<EducationRecruitment />} />
          <Route path="/Permanent-Hiring-Solutions" element={<PermanentRecruitment />} />
          <Route path="/Tailored-Solutions-For-Employers" element={<TailoredSolutionsForEmployers />} />
          <Route path="/Fluid-Workforce-Solutions" element={<FluidWorkforce />} />
          <Route path="/ThinkBeyond-Privacy-Policy" element={<ThinkBeyondPrivacyPolicy />} />

          {/* <Route path="/verify-account/:id/:token" element={<PageEmailVerification />} /> */}
          <Route path="/Email-Account-Verification/:id/:token" element={<EmailVerification />} />
          <Route path="/Forgot-Password-Reset-Request" element={<ForgotPassword />} />
          <Route path="/Password-Reset-Link/:id/:resetToken" element={<PasswordResetLink />} />

          <Route
            path="/Recruitment-Process-Outsourcing"
            element={<RecruitmentProcess />}
          />
          <Route
            path="/Software-Development-Services"
            element={<SoftwareDevelopment />}
          />
          <Route path="/Candidate-Sourcing" element={<CandidateSourcing />} />
          {/* <Route path="/About-Us" element={<AboutUs />} /> */}
          <Route path="/About-Us/Our-Mission" element={<OurMission />} />
          <Route path="/About-Us/Our-Vision" element={<OurVision />} />
          <Route path="/Services" element={<OurCoreServices />} />
          <Route
            path="/Services/IT-Infrastructure"
            element={<ITInfrastructure />}
          />
          <Route path="/Behavioral-Health" element={<BehavioralHealth />} />
          <Route path="/Education" element={<Education />} />
          <Route
            path="/Health-Billing-&-Collections"
            element={<HealthBillingCollection />}
          />
          <Route
            path="/Healthcare-M-&-A"
            element={<HealthcareMergersAndAcquisitions />}
          />
          <Route
            path="/Recruiting-&-Leadership"
            element={<RecruitingAndLeadership />}
          />
          <Route
            path="/Behavioral-Health-Credentialing-&-Contracting"
            element={<BehavioralHealthCredentialingAndContracting />}
          />
          <Route
            path="/Web-Development-Services"
            element={<WebDevelopmentServices />}
          />
          <Route path="/Data-Analytics" element={<DataAnalytics />} />
          <Route path="/Higher-Education" element={<HigherEducation />} />
          <Route
            path="/Education-Counselling"
            element={<EducationCounselling />}
          />
          <Route path="/Infrastructure" element={<Infrastructure />} />
          <Route path="/Employers" element={<Employers />} />

          {/* <Route path="/Search-Jobs" element={<BrowseJobs />} /> */}
          <Route path="/Non-IT-Jobs" element={<NonITJobs />} />
          <Route path="/Search" element={<SearchJobFilter />} />
          <Route path="/Job-Details/:slug" exact element={<JobDetails />} />
          <Route
            path="/Non-IT-Job-Details/:slug"
            exact
            element={<NonITJobDetails />}
          />

          {/* <Route path="/Contact-Us" element={<ContactUs />} /> */}
          {/* <Route path="/Thank-You-For-Contacting-Us" element={<ThankYou />} /> */}

          {/* <Route path="/Login" element={<Login />} /> */}
          {/* <Route path="/Register" element={<Register />} /> */}
          {/* <Route
            path="/user-verify-account/:id/:token"
            element={<EmailVerification />}
          /> */}
          {/* <Route path="/Account/Forgot-Password" element={<ForgotPassword />} /> */}
          {/* <Route
            path="/reset-password/:id/:resetToken"
            element={<ResetPassword />}
          /> */}

          <Route
            path="/User-Subscription-Form"
            element={<UsersSubscriptionFormDetails />}
          />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          {/* <Route path="/auth/linkedin/callback" element={<LinkedInCallback />} />
          <Route path="/auth/success" element={<AuthSuccess />} /> */}
          <Route path="/auth/success" element={<AuthSuccess />} />
          

          <Route path="/" element={<AdminRoute />}>
            {/* <Route path="/Admin/Dashboard" element={<AdminDashboardHome />} /> */}
            <Route path="/Admin/Dashboard" element={<AdminDashboardHome />} />


            <Route
              path="/Admin/Countries/Add-Country"
              element={<AddCountry />}
            />


            <Route path="/Admin/Test-Add-Job" element={<AddTestJob/>} />
            <Route path="/Admin/Test-Manage-Jobs" element={<ManageTestJobs />} />
            <Route path="/Admin/Test-Manage-Qualifications" element={<ManageAdminJobTestQualifications />} />
            <Route path="/Admin/Test-Add-Qualification" element={<AddTestQualification />} />
            <Route path="/Admin/Test-Manage-Work-Experiences" element={<ManageAdminTestWorkExperiences />} />
            <Route path="/Admin/Test-Add-Work-Experience" element={<AddTestWorkExperience />} />
            <Route path="/Admin/Manage-Test-Work-Modes" element={<ManageAdminTestWorkModes />} />
            <Route path="/Admin/Add-Test-Work-Mode" element={<AddTestWorkMode />} />
            <Route path="/Admin/Add-Test-Country" element={<AddTestCountry />} />
            <Route path="/Admin/Manage-Test-Countries" element={<ManageAdminJobTestCountries />} />
            <Route path="/Admin/Add-Test-Province" element={<AddTestProvince />} />
            <Route path="/Admin/Manage-Test-Provinces" element={<ManageAdminTestProvinces />} />
            <Route path="/Admin/Add-Test-Sector" element={<AddTestSector />} />
            <Route path="/Admin/Manage-Test-Sectors" element={<ManageAdminTestSectors />} />
            <Route path="/Admin/Add-Test-Salary" element={<AddTestSalary />} />
            <Route path="/Admin/Manage-Test-salaries" element={<ManageAdminTestSalaries />} />
            
            <Route path="/Admin/All-Contact-Us-Messages" element={<AllContactUsMessages />} />
            <Route path="/Admin/Test-View-Contact-Message/:id" element={<TestViewContactMessage />} />
            <Route path="/Admin/Test-Email-Subscribers" element={<TestAdminSubscribers />} />

            <Route path="/Admin/Add-Test-Work-Authorization" element={<AddTestWorkAuthorization />} />
            <Route path="/Admin/Manage-Test-Work-Authorizations" element={<ManageAdminTestWorkAuthorizations />} />
            <Route path="/Admin/Test-Change-Password" element={<AdminTestChangePassword />} />
            
            


            <Route
              path="/Admin/Countries/Manage-Countries"
              element={<ManageCountries />}
            />
            <Route path="/Admin/Salaries/Add-Salary" element={<AddSalary />} />
            <Route
              path="/Admin/Salaries/Manage-Salaries"
              element={<ManageSalaries />}
            />
            <Route
              path="/Admin/Provinces/Add-Province"
              element={<AddProvince />}
            />
            <Route
              path="/Admin/Provinces/Manage-Provinces"
              element={<ManageProvinces />}
            />
            <Route path="/Admin/Sectors/Add-Sector" element={<AddSector />} />
            <Route
              path="/Admin/Sectors/Manage-Sectors"
              element={<ManageSectors />}
            />
            <Route
              path="/Admin/Products/Manage-Products"
              element={<ManageProducts />}
            />
            <Route
              path="/Admin/ProductCategories/Manage-Product-Categories"
              element={<ManageProductCategories />}
            />
            <Route
              path="/Admin/Qualifications/Add-Qualification"
              element={<AddQualification />}
            />
            <Route
              path="/Admin/Qualifications/Manage-Qualifications"
              element={<ManageQualifications />}
            />
            <Route
              path="/Admin/WorkExperiences/Add-Work-Experience"
              element={<AddWorkExperience />}
            />
            <Route
              path="/Admin/WorkExperiences/Manage-Work-Experiences"
              element={<ManageWorkExperiences />}
            />
            <Route
              path="/Admin/WorkModes/Add-Work-Mode"
              element={<AddWorkMode />}
            />
            <Route
              path="/Admin/WorkModes/Manage-Work-Modes"
              element={<ManageWorkModes />}
            />
            <Route
              path="/Admin/WorkAuthorizations/Add-Work-Authorization"
              element={<AddWorkAuthorization />}
            />
            <Route
              path="/Admin/WorkAuthorizations/Manage-Work-Authorizations"
              element={<ManageWorkAuthorizations />}
            />
            <Route path="/Admin/Jobs/Post-Job" element={<PostJob />} />
            <Route
              path="/Admin/Jobs/Post-Non-IT-Job"
              element={<PostNonITJob />}
            />
            <Route path="/Admin/Jobs/Manage-Jobs" element={<ManageJobs />} />
            <Route
              path="/Admin/Jobs/Manage-Non-IT-Jobs"
              element={<ManageNonITJobs />}
            />
            <Route
              path="/Admin/Jobs/DeleteJob/:slug"
              element={<DeleteNonITJob />}
            />
            <Route
              path="/Admin/Jobs/Delete-Job/:slug"
              element={<DeleteJob />}
            />
            <Route
              path="/Admin/Jobs/Update-Job/:slug"
              element={<UpdateJob />}
            />

            <Route
              path="/Admin/Jobs/View-Job/:slug"
              element={<ViewJobDetails />}
            />
            <Route
              path="/Admin/Jobs/ViewJob/:slug"
              element={<ViewNonITJobDetails />}
            />
            <Route
              path="/Admin/Jobs/UpdateJob/:slug"
              element={<UpdateNonITJob />}
            />
            <Route
              path="/Admin/Contact-Messages/Manage-Contact-Us-Messages"
              element={<ManageContactMessages />}
            />
            <Route
              path="/Admin/Contact-Messages/View-Message/:id"
              element={<ViewContactMessage />}
            />
            <Route
              path="/Admin/Contact-Messages/Delete-Message/:id"
              element={<DeleteContactMessage />}
            />
            <Route
              path="/Account/Change-Password"
              element={<AdminChangePassword />}
            />
            <Route path="/Admin/Subscribers" element={<Subscribers />} />
          </Route>


         

          <Route path="/" element={<ApplicantRoute />}>
            {/* <Route
              path="/Applicant/Dashboard"
              element={<ApplicantDashboardHome />}
            /> */}
             {/* %%%%%%%%%%%%%%%%%%%%%%%% Applicant Routes Starts %%%%%%%%%%%%%%%%%%%%%%%%%% */}
          <Route path="/Applicant-Profile-Dashboard" element={<TestApplicantDashboard />} />
          <Route path={`/Profile/${auth?.user?.userId}`} element={<PageProfile />} />
          <Route path={`/Applicant-Resume-Upload/${auth?.user?.userId}`} element={<ApplicantResumeUpload />}  />
          <Route path="/User-Change-Password" element={<PageChangePassword />} />
          {/* %%%%%%%%%%%%%%%%%%%%%%%% Applicant Routes Ends % %%%%%%%%%%%%%%%%%%%%%%%%%% */}
            <Route path="/Account/Apply-Job/:slug" element={<ApplyJobForm />} />
            <Route
              path="/Account/Apply-Non-IT-Job/:slug"
              element={<ApplyNonITJobForm />}
            />
            {/* <Route
              path={`/Applicant/Profile/${auth?.user?.userId}`}
              element={<ApplicantProfile />}
            /> */}
            <Route
              path="/Applicant/Jobs/Applied-Jobs"
              element={<ApplicantAppliedJobs />}
            />
            <Route
              path="/Applicant/Change-Password"
              element={<ApplicantChangePassword />}
            />
            {/* <Route
              path={`/Resume-Upload/${auth?.user?.userId}`}
              element={<ResumeUpload />}
            /> */}
            <Route
              path="/Applicant/Delete-Education/:id"
              element={<DeleteEducation />}
            />
            <Route
              path="/Applicant/Delete-Applicant-Experience/:id"
              element={<DeleteApplicantExperience />}
            />
            <Route
              path="/Applicant/Delete-Skill/:id"
              element={<DeleteSkill />}
            />
            <Route
              path="/Applicant/Delete-Resume/:id"
              element={<DeleteResume />}
            />
            <Route path="/Orders" element={<Orders />} />
            {/* <Route path="/oauth-success" element={<OAuthSuccess />} /> */}
          </Route>
          <Route path="/Thinkbeyond-Access-Denied" element={<UnAuthorized />} />
          <Route path="/Unsubscribe" element={<Unsubscribe />} />
          <Route path="/Online-Shop" element={<Shop />} />
          <Route path="/Product-Detail/:slug" element={<ProductDetail />} />
          <Route path="/Cart-Details" element={<Cart />} />
          <Route path="Privacy-Policy" element={<PrivacyPlicy />} />
        </Routes>
      </Suspense>
      <StyledContainer />
    </>
  );
}

export default App;
