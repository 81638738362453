import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import API from "../helpers/API";
import Spinner from "../ui/Spinner/Spinner";

export const AdminRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [auth] = useAuth();
  const navigate = useNavigate();

  const checkAuthorization = useCallback(async () => {
    if (!auth?.token) {
      setIsAuthorized(false);
      setIsLoading(false);
      return;
    }

    try {
      const res = await API.get("/auth/adminRoute");
      setIsAuthorized(res.data.ok);
    } catch (error) {
      console.error("Admin authorization check failed:", error);
      setIsAuthorized(false);
    } finally {
      setIsLoading(false);
    }
  }, [auth?.token]);

  useEffect(() => {
    checkAuthorization();
  }, [checkAuthorization]);

  if (isLoading) {
    return <div className="loading-indicator">Verifying access...</div>;
  }

  return isAuthorized ? (
    <Outlet />
  ) : (
    <Spinner path="/Login" />
  );
};

// export default AdminRoute;
