import axios from "axios";

export default axios.create({
  //baseURL: "http://localhost:8000"
  baseURL: "https://backend.thethinkbeyond.com"
  //withCredentials: true,
  //credentials: "include"
});


