// import React, { useState, useEffect } from "react";
// import { Outlet } from "react-router-dom";
// import { useAuth } from "../Context/AuthContext";
// import API from "../helpers/API";
// import Spinner from "../ui/Spinner/Spinner";

// export const ApplicantRoute = () => {
//   const [ok, setOk] = useState(false);
//   // eslint-disable-next-line
//   const [auth, setAuth] = useAuth();

//   useEffect(() => {
//     const authCheck = async () => {
//       try {
//         const res = await API.get("/auth/applicantRoute");
//         setOk(res.data.ok);
//       } catch (error) {
//         setOk(false);
//       }
//     };
//     if (auth?.token) authCheck();
//     else setOk(false); // Immediately set to false if no token
//   }, [auth?.token]);
//   return ok ? <Outlet /> : <Spinner path="" />;
// };


import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import API from "../helpers/API";
import Spinner from "../ui/Spinner/Spinner";

export const ApplicantRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [auth] = useAuth();
  const navigate = useNavigate();

  const checkAuthorization = useCallback(async () => {
    if (!auth?.token) {
      setIsAuthorized(false);
      setIsLoading(false);
      return;
    }

    try {
      const res = await API.get("/auth/applicantRoute");
      setIsAuthorized(res.data.ok);
    } catch (error) {
      console.error("Job Seeker Authorization Check failed:", error);
      setIsAuthorized(false);
    } finally {
      setIsLoading(false);
    }
  }, [auth?.token]);

  useEffect(() => {
    checkAuthorization();
  }, [checkAuthorization]);

  if (isLoading) {
    return <div className="loading-indicator">Verifying Access...</div>;
  }

  return isAuthorized ? (
    <Outlet />
  ) : (
    <Spinner path="/Login" />
  );
};

// export default AdminRoute;
