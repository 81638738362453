import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const REDIRECT_DELAY = 1; // Seconds before redirect
const DEFAULT_REDIRECT_PATH = "/Login";
const ACCESS_DENIED_MESSAGE = "ACCESS DENIED. You do not have the Authorization to View this Page!";

/**
 * Spinner component for redirecting unauthorized users
 * @param {Object} props - Component props
 * @param {string} [props.path="/Login"] - Path to redirect to
 * @returns {JSX.Element} Spinner component
 */
const Spinner = ({ path = DEFAULT_REDIRECT_PATH }) => {
  const [count, setCount] = useState(REDIRECT_DELAY);
  const navigate = useNavigate();
  const location = useLocation();
  
  const notifyErr = useCallback((msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 20000,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "light"
    });
  }, []);

  // Handle countdown timer
  useEffect(() => {
    if (count <= 0) return;
    
    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
    
    return () => clearInterval(interval);
  }, [count]);

  // Handle redirect when countdown reaches zero
  useEffect(() => {
    if (count !== 0) return;
    
    navigate(path, { 
      state: { from: location }, 
      replace: true 
    });
    
    notifyErr(ACCESS_DENIED_MESSAGE);
  }, [count, navigate, location, path, notifyErr]);

  return (
    <div className="spinner-container">
      <section
        className="spinner-section"
        style={{
          background: "#ffffff",
          paddingTop: "50px",
          paddingBottom: "200px"
        }}
      >
        <div className="container-fluid d-flex align-items-center justify-content-center">
          <div className="text-center">
            <div className="loader" aria-label="Loading indicator">Loading...</div>
            <h1>
              Redirecting you in {count} second{count !== 1 ? 's' : ''}
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Spinner;