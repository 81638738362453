import React, { useState, useEffect, useContext, createContext } from "react";
import API from "../helpers/API";
const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ user: null, token: "" });

  // Set the default API Authorization header
  // API.defaults.headers.common["Authorization"] = auth?.token;
  API.defaults.headers.common["Authorization"] = auth?.token
    ? `Bearer ${auth.token}`
    : "";
  useEffect(() => {
    const data = localStorage.getItem("userAuthDetails");
    if (data) {
      try {
        const parseData = JSON.parse(data);
        setAuth({ user: parseData.user, token: parseData.token });
      } catch (error) {
        console.error("Error parsing auth data:", error);
        localStorage.removeItem("userAuthDetails");
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export { useAuth, AuthProvider };
